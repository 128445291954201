import React, { useEffect } from "react";
import useSWR from "swr";
import Img from "react-cool-img";
import Header from "../../components/Header/Header";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Loader from "../../components/Loader/Loader";
import parseBlocks from "../../utils/editorjs-parser";
import "./AboutPage.scss";
import { generateImageUrl } from "../../utils/utils";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error } = useSWR(`/homepage`);

  if (error) return "error";

  if (!data && !error) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="about-page">
        <PageWrapper>
          <Img src={generateImageUrl(data.hero)} width="100%" />
          <div className="text-wrapper">
            <h1>About Us</h1>
            {parseBlocks(data.description)}
          </div>
          <div className="mission-wrapper">
            <h2>Mission</h2>
            <p>{data.description_two}</p>
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default AboutPage;
