import { useEffect } from "react";
import useSWR from "swr";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import Loader from "../../components/Loader/Loader";

import "./VisitorInfoPage.scss";

const VisitorInfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error } = useSWR(`/settings`);

  if (error) return "error";

  if (!data && !error) return <Loader />;


  return (
    <>
      <Header variant="white" />
      <main className="visitor-info-page">
        <PageWrapper>
          <PageHeading text="Visitor Information" />
          <div className="wrapper">
            <h1>Opening hours</h1>
            <div className="table">
              <div>Sunday</div>
              <div className="line"></div>
              <div>{data.sunday}</div>
              <div>Monday</div>
              <div className="line"></div>
              <div>{data.monday}</div>
              <div>Tuesday</div>
              <div className="line"></div>
              <div>{data.tuesday}</div>
              <div>Wednesday</div>
              <div className="line"></div>
              <div>{data.wednesday}</div>
              <div>Thursday</div>
              <div className="line"></div>
              <div>{data.thursday}</div>
              <div>Friday</div>
              <div className="line"></div>
              <div>{data.friday}</div>
              <div>Saturday</div>
              <div className="line"></div>
              <div>{data.saturday}</div>
            </div>
            <h1>Charges</h1>
            <div className="table">
              <div>Maldivian</div>
              <div className="line"></div>
              <div>{data.maldivian}</div>

              {data.work_visa_permanent_resident &&
                <>
                <div>Work Visa/Permanent Resident</div>
                <div className="line"></div>
                <div>{data.work_visa_permanent_resident}</div>
                </>
              }
              
              <div>Foreigner</div>
              <div className="line"></div>
              <div>{data.foreigner}</div>
              <div>Kids</div>
              <div className="line"></div>
              <div>{data.kids}</div>
            </div>
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default VisitorInfoPage;
