import {FaTimes} from "react-icons/fa";
import React from "react";
import "./Modal.scss";

const Modal = ({ modalClose, show, children }) => {
  const showHideClassName = show ? "modal d-block" : "modal d-none";

  return (
    <div className={showHideClassName}>
      <div className="modal-container">
        <div className="modal-content-">
        	{children}
          <FaTimes onClick={(e)=>modalClose(e)} className="closeBtn" />
        </div>
      </div>
    </div>
  );
};


export default Modal;