import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modals/Modal";/*
import { generateImageUrl } from "../../utils/utils";*/
import { CMS_BASE_URL } from "../../config/config";
import { API_BASE_URL } from "../../config/config";
import moment from "moment";

import "./EventListingPage.scss";
import Img from "react-cool-img";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { Link } from "react-router-dom";

const EventListingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = useState({
    filter: "past",
    now: "",
    upcoming: "",
    past: "active",
    filterStartDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    filterEndDate: moment().clone().endOf("month").format("YYYY-MM-DD"),
    modal: false,
    statusMessage: "",
    currentEvent: "",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const { data: perm, permError } = useSWR(`/permanent-collection`);
  let { data: event, error: eventError } = useSWR(`/event-page`);
  let { data: eventsUpcoming, error: eventsUpcomingError } = useSWR(`/event-related/0/upcoming`);
  let { data: eventsPast, error: eventsPastError } = useSWR(`/event-related/0/past`);
  let { data: eventsNow, error: eventsNowError } = useSWR(`/event-related/0/now`);

  const handleFilter = (val) => {
    const target = val;
    if (target === "now") {
      setState({
        ...state,
        now: "active",
        upcoming: "",
        past: "",
        filter: "now",
      });
    } else if (target === "upcoming") {
      setState({
        ...state,
        now: "",
        upcoming: "active",
        past: "",
        filter: "upcoming",
      });
    } else {
      setState({
        ...state,
        now: "",
        upcoming: "",
        past: "active",
        filter: "past",
      });
    }
  };

  const modalOpen = (event) => {
    setState({ ...state, modal: true, currentEvent: event });
  };

  const modalClose = (e) => {
    e.preventDefault();
    setState({ ...state, modal: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatusMessage("Sending the message...");

    if (name === "" || email === "" || contact === "" || message === "") {
      console.log("empty");
      return;
    }
    let postData = {
      name: name,
      email: email,
      contact: contact,
      event: state.currentEvent,
      message: message,
    };

    fetch(API_BASE_URL + "/event-application", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }).then((response) => {
      if (response.status === 200) {
        setStatusMessage("Thanks for the sign up. We'll be in touch shortly.");
      } else {
        setStatusMessage(
          "Something went wrong. Please try again later or give us a call."
        );
      }
    });

    setName("");
    setEmail("");
    setContact("");
    setMessage("");
  };

  const eventContainer = (item,index) => {
      
      return (
      <div className="event" key={index}>
          <Link to={"/event/"+item.id}>
            <div className="image-container">
              <Img src={CMS_BASE_URL + item.primary_location} className="bg-image" />
            </div>
          </Link>

          <div className="body">
            <div className="link">
              {item.type === "Other" ? "Events" : item.type + " Gallery"}
            </div>
            <div className="wrapper">
              <div className="content">
                <Link to={"/event/"+item.id}>
                  <h3 className="serif f-26">{item.name}</h3>
                </Link>
                <p>{item.description}</p>
              </div>
              {item.type !== 'Permanent' &&
              <div className="meta">
                <div className="dates">
                  <div className="date">
                    <div className="day">
                      {moment(item.start_date).format("DD")}
                    </div>
                    <div className="week-day">
                      {moment(item.start_date).format("dddd")}
                    </div>
                    <div className="month">
                      {moment(item.start_date).format("MMMM")} {moment(item.start_date).format("YYYY")}
                    </div>
                  </div>

                  {
                     
                    (moment(item.application_deadline).format() >=
                    moment().format() ? (
                    <>
                      <div className="sign-up-before">
                        <div className="link">sign up before</div>
                        <div className="f-10">
                          {moment(item.application_deadline).format(
                            "MMMM Do YYYY"
                          )}
                        </div>
                      </div>

                      <button
                        className="sign-up-button"
                        onClick={() => modalOpen(item.name)}
                      >
                        sign up
                      </button>
                    </>
                  ) : (
                    <div className="sign-up-before">
                      {/*<div className="link">sign up closed</div>*/}
                    </div>
                  ))
                }
                </div>
              </div> }
            </div>
          </div>
        </div>
      ); 
  }

  if (eventError) return "error";
  if (eventsUpcomingError) return "error";
  if (eventsPastError) return "error";
  if (eventsNowError) return "error";
  if (permError) return "error";

  if (!event && !eventError) return <Loader />;
  if (!eventsUpcoming && !eventsUpcomingError) return <Loader />;
  if (!eventsPast && !eventsPastError) return <Loader />;
  if (!eventsNow && !eventsNowError) return <Loader />;
  if (!perm && !permError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="event-listing-page">
        {/*<div className="cover-image">
          <Img src={generateImageUrl(event.hero)} className="bg-image" />
        </div>*/}
        <PageWrapper>
          <h2>Exhibitions & Events</h2>

          
            
            {eventContainer(perm)}
            
          

          <div className="filters">
            <div
              className={state.past}
              onClick={handleFilter.bind(null, "past")}
            >
              Past
            </div>
            <div
              className={state.now}
              onClick={handleFilter.bind(null, "now")}
            >
              Current
            </div>
            <div
              className={state.upcoming}
              onClick={handleFilter.bind(null, "upcoming")}
            >
              Upcoming
            </div>
            
          </div>
          <div className="grid">
              
              {state.filter === 'past' && (
                eventsPast.length !== 0 ?
                  (eventsPast.map((item,index) =>
                      {
                          return (
                              eventContainer(item,index)
                          );
                      })
                  )
                   : (<div>No past events.</div>) )
             }

              {state.filter === 'now' && (
                eventsNow.length !== 0 ?
                  (eventsNow.map((item,index) =>
                      {
                          return (
                              eventContainer(item,index)
                          );
                      })
                  )
                   : (<div>No current events.</div>) )
             }

             {state.filter === 'upcoming' && (
                eventsUpcoming.length !== 0 ?
                  (eventsUpcoming.map((item,index) =>
                      {
                          return (
                              eventContainer(item,index)
                          );
                      })
                  )
                   : (<div>No upcoming events.</div>) )
             }

             
              
          </div>
        </PageWrapper>
      </main>

      <Modal show={state.modal} modalClose={modalClose}>
        <h4>Sign Up for the {state.currentEvent}</h4>
        <div>{statusMessage}</div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            name="contact"
            placeholder="Contact Number"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <input
            type="email"
            className="email"
            placeholder="e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <input type="submit" value="Submit" className="" />
        </form>
      </Modal>
    </>
  );
};

export default EventListingPage;
