import React, { useEffect } from "react";
import useSWR from "swr";
import Img from "react-cool-img";
import Hero from "../../components/Hero/Hero";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import { ReactComponent as RightIcon } from "../../assets/icons/right-chevron.svg";

import "./HomePage.scss";
import Header from "../../components/Header/Header";
import ArtistCard from "../../components/Cards/ArtistCard";
import { Link } from "react-router-dom";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: eventsFeat, eventsFeatError } = useSWR(`/featured-events/2`);
  const { data: artistsFeat, artistsFeatError } = useSWR(`/featured-artists/3`);
  const { data: perm, permError } = useSWR(`/permanent-collection`);

  if (eventsFeatError) return "error";
  if (artistsFeatError) return "error";
  if (permError) return "error";
  if (!eventsFeat && !eventsFeatError) return <Loader />;
  if (!artistsFeat && !artistsFeatError) return <Loader />;
  if (!perm && !permError) return <Loader />;

  return (
    <>
      <Header variant="transparent" />
      <main className="home-page">
        {perm && perm.length !== 0 && (
          <Link to={"/event/" + perm.id}>
            <Hero image={generateImageUrl(perm.hero)}>
              <h1 className="serif">{perm.name}</h1>
              <p>{perm.description}</p>
            </Hero>
          </Link>
        )}
        <PageWrapper>
          <div className="featured-events">
            {eventsFeat[0] && eventsFeat[0].length !== 0 && (
              <Link to={"/event/" + eventsFeat[0].id}>
                <div className="event white-overlay">
                  <Img
                    src={generateImageUrl(eventsFeat[0].primary)}
                    className="bg-image"
                  />
                  
                    <div className="f-12">FEATURED EVENT</div>
                    <div className="f-26 serif">{eventsFeat[0].name}</div>
                    <div className="mt-auto link">READ MORE</div>
                  </div>
                  
                
              </Link>
            )}

            {eventsFeat[1] && eventsFeat[1].length !== 0 && (
              <Link to={"/event/" + eventsFeat[1].id}>
                <div className="event white-overlay">
                  <Img
                    src={generateImageUrl(eventsFeat[1].primary)}
                    className="bg-image"
                  />
                  <div className="f-12">FEATURED EVENT</div>
                  <div className="f-26 serif">{eventsFeat[1].name}</div>
                  <div className="mt-auto link">READ MORE</div>
                </div>
              </Link>
            )}
          </div>

          <div className="featured-artists">
            <div className="space-between">
              <h2 className="f-sans">Featured artists</h2>
              <div className="discover link">
                <Link to="/artists">
                  Discover
                  <RightIcon />
                </Link>
              </div>
            </div>
            <div className="grid">
              {artistsFeat.map((item, index) => {
                return (
                  <ArtistCard
                    name={item.fullname}
                    image={generateImageUrl(item.profile)}
                    id={item.id}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          {/*<div className="permanent-collection">
            <Img src={generateImageUrl(perm.hero)} />
            <div className="text-wrapper">
              <h2>permanent collection</h2>
              <p>{perm.description}</p>
              <div className="view-collection link">
                <Link to={"/gallery/" + perm.id}>View collection</Link>
              </div>
            </div>
          </div>*/}
        </PageWrapper>
      </main>
    </>
  );
};

export default HomePage;
