import React, { useEffect, useState } from "react";
import Img from "react-cool-img";
import "react-nice-dates/build/style.css";
import { API_BASE_URL } from "../../config/config";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useSWR from "swr";
import MapImage from "../../assets/images/map.png";
import Loader from "../../components/Loader/Loader";

import "./ContactPage.scss";
const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const { data, error } = useSWR(`/settings`);

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatusMessage("Sending the message...");

    if (name === "" || email === "" || contact === "" || message === "") {
      console.log("empty");
      return;
    }

    let postData = {
      name: name,
      email: email,
      contact_number: contact,
      message: message,
    };

    fetch(API_BASE_URL + "/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }).then((response) => {
      if (response.status === 200) {
        setStatusMessage("Thank you, we have received the message.");
      } else {
        setStatusMessage(
          "Something went wrong. Please try again later or give us a call."
        );
      }
    });

    setName("");
    setEmail("");
    setContact("");
    setMessage("");
  };

  if (error) return "error";

  if (!data && !error) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="contact-page">
        <PageWrapper>
          <PageHeading text="Contact us" />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div>{statusMessage}</div>
            <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            name="contact"
            placeholder="Contact Number"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <input
            type="email"
            className="email"
            placeholder="e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
            <button>Submit</button>
          </form>

          <div className="contact-info">
            <p>Tel <a href={"tel:"+data.contact_number_one}>{data.contact_number_one}</a> </p>
            <p>Email <a href={"mailTo:"+data.email}>{data.email}</a> </p>
            <p>{data.address_field_one}, {data.address_field_two}</p>
          </div>
        </PageWrapper>
        <div className="map-container">
          <Img src={MapImage} className="bg-image" />
        </div>
      </main>
    </>
  );
};

export default ContactPage;
