import React, { useEffect } from "react";
import Img from "react-cool-img";
import Header from "../../components/Header/Header";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";

import "./BlogListingPage.scss";
import { Link } from "react-router-dom";
const BlogListingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { data: featured, error: featuredError } = useSWR(`/blogs-featured/1`);
  let { data: stories, error: storiesError } = useSWR(`/blogs`);

  if (featuredError) return "error";
  if (storiesError) return "error";

  if (!featured && !featuredError) return <Loader />;
  if (!stories && !storiesError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="blog-listing-page">
        <PageWrapper>
          <div className="featured-post">
            <Link to={"/post/"+featured[0].id}>
              <Img src={generateImageUrl(featured[0].hero)} />
            </Link>
            <div className="text-wrapper">
              <Link to={"/post/"+featured[0].id}>
                <h2>{featured[0].headline}</h2>
              </Link>
              <p>
                {featured[0].abstract}
              </p>
            </div>
          </div>

          <div className="grid">
            {stories.map((item,index) => {
                return (
                  <Link to={"/post/"+item.id} key={index}>
                  <div className="post" >
                    <div className="image-container">
                      <Img src={generateImageUrl(item.hero)} className="bg-image" />
                    </div>
                    <h3>{item.headline}</h3>
                  </div>
                  </Link>
                )
            })}
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default BlogListingPage;
