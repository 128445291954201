import React from "react";
import Img from "react-cool-img";

import "./Hero.scss";

const Hero = ({ image, children }) => {
  return (
    <div className="hero">
      <div className="image-container">
        <Img src={image} />
      </div>
      <div className="wrapper">{children}</div>
    </div>
  );
};

export default Hero;
