import React, { useState } from "react";
import { Link } from "react-router-dom";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";

import  Logo  from "../../assets/icons/artgallery-logo.svg";
import { ReactComponent as DownIcon } from "../../assets/icons/down-chevron.svg";
import "./Header.scss";

// variant is "transparent" or "white"
const Header = ({ variant }) => {
  const {
    buttonProps: buttonProps1,
    itemProps: itemProps1,
    isOpen: isOpen1,
    // setIsOpen: setIsOpen1,
  } = useDropdownMenu(2);
  const {
    buttonProps: buttonProps2,
    itemProps: itemProps2,
    isOpen: isOpen2,
    // setIsOpen: setIsOpen2,
  } = useDropdownMenu(2);

  const [mobileMenuHidden, setMobileMenuHidden] = useState(true);

  return (
    <header className={`header ${variant}`}>
      <div className="wrapper">
        <div className="  ">
          <Link to="/">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div>
          <nav className="desktop-nav">
            <Link to="/events">Exhibitions & Events</Link>
            <div className="dropdown-group">
              <button {...buttonProps1} className="dropdown-button">
                Art & Artists
                <DownIcon />
              </button>
              <div
                className={`dropdown ${isOpen1 ? "visible" : ""}`}
                role="menu"
              >
                <Link {...itemProps1[0]} to="/artists">
                  Artists
                </Link>
                <Link {...itemProps1[1]} to="/stories">
                  Stories
                </Link>
              </div>
            </div>

            <Link to="/3d-galleries">Virtual Gallery</Link>
            <div className="dropdown-group">
              <button {...buttonProps2} className="dropdown-button">
                Commercial
                <DownIcon />
              </button>
              <div
                className={`dropdown ${isOpen2 ? "visible" : ""}`}
                role="menu"
              >
                <Link {...itemProps2[0]} to="/venue-hire">
                  Venue Hire
                </Link>

                <Link {...itemProps2[1]} to="/jobs-procurement">
                  Jobs & Procurement
                </Link>
              </div>
            </div>
          </nav>
          <div
            onClick={() => setMobileMenuHidden(false)}
            className="menu-button"
          >
            Menu
          </div>
          <div className="mobile-nav" hidden={mobileMenuHidden}>
            <div
              className="close-button"
              onClick={() => setMobileMenuHidden(true)}
            >
              Close
            </div>
            <nav>
              <Link className="main-link" to="/events">
                Exhibitions & Events
              </Link>
              <div className="main-link">Art & Artists</div>
              <Link to="/artists">Artists</Link>
              <Link to="/stories">Stories</Link>
              <Link className="main-link" to="/3d-galleries">
                Virtual Gallery
              </Link>
              <div className="main-link">Commercial</div>
              <Link to="/press-releases">Press</Link>
              <Link to="/venue-hire">Venue Hire</Link>
              <Link to="/jobs-procurement">Jobs & Procurement</Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
