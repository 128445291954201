import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/icons/artgallery-logo.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="wrapper">
        <div className="logo-container">
          <Logo className="logo" />
        </div>
        <div>
          <div className="footer-heading">The Gallery</div>
          <Link to="/events">Exhibitions & Events</Link>
          <Link to="/artists">Artists</Link>
          <Link to="/stories">Stories</Link>
          <Link to="/3d-galleries">Virtual Gallery</Link>
        </div>

        <div>
          <div className="footer-heading">About Us</div>
          <Link to="/visitor-info">Visitor Info</Link>
          <Link to="/about">Who we are</Link>
          <Link to="/organisation">Organisation</Link>
          <Link to="/contact">Contact Us</Link>
          <Link to="/press">Press</Link>
        </div>

        <div>
          <div className="footer-heading">Commercial</div>
          <Link to="/venue-hire">Venue Hire</Link>
          <Link to="/jobs-procurement">Jobs & Procurement</Link>
        </div>

        <div>
          <div className="footer-heading">Connect</div>
          <div className="social-link">Facebook</div>
          <div className="social-link">Instagram</div>
          <div className="social-link">Twitter</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
