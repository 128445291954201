import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SWRConfig } from "swr";
import { fetcher } from "./config/config";


ReactDOM.render(
  <React.StrictMode>
  	 <SWRConfig
      value={{
        fetcher: fetcher,
      }}
    >
    <App />
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById("root")
);
