import React, { useState } from "react";
import Img from "react-cool-img";

import "./ArtworkCard.scss";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const ArtworkCard = ({
  image,
  title,
  event,
  artist,
  shortDescription,
  longDescription,
  medium,
  size,
}) => {
  const [popupHidden, setPopupHidden] = useState(true);

  return (
    <>
      <div className="artwork-card" onClick={() => setPopupHidden(false)}>
        <Img src={image} />
        <h3>{title}</h3>
        <div className="link">{event}</div>
        <p>{shortDescription}</p>
      </div>
      <div className="artwork-popup" hidden={popupHidden}>
        <div className="wrapper">
          <div className="header">
            <h1 className="serif">{title}</h1>
            <CloseIcon onClick={() => setPopupHidden(true)} />
          </div>
          <div className="meta">
            {event && <div className="link">{event}</div>}
            {artist && <div className="link">{artist}</div>}
            <div className="link">{medium}</div>
            <div className="link">{size}</div>
          </div>
          <Img src={image} />
          <p>{longDescription}</p>
        </div>
      </div>
    </>
  );
};

export default ArtworkCard;
