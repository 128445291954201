import React, { useEffect, useState } from "react";
import useSWR from "swr";
import Header from "../../components/Header/Header";
import ArtistCard from "../../components/Cards/ArtistCard";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import { ReactComponent as RightIcon } from "../../assets/icons/right-chevron.svg";
import "./ArtistListinPage.scss";



const ArtistListinPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [visibleItems, setVisibleItems] = useState(4);

  let { data, error } = useSWR(`/artists`);

  if (error) return "error";

  if (!data && !error) return <Loader />;

  const handleClick = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 8);
  };

  const cardComponent = data.slice(0, visibleItems).map((item, index) => {
    return (
      <ArtistCard
        name={item.fullname}
        image={generateImageUrl(item.profile)}
        key={index}
        id={item.id}
      />
    );
  });

  return (
    <>
      <Header variant="white" />
      <main className="artist-listing-page">
        <PageWrapper>
          <div className="heading">
            <h2>Artists</h2>
            <div className="line"></div>
          </div>

          <div className="grid">{cardComponent}</div>
          <div className="view-more link" onClick={handleClick}>
            view more <RightIcon />
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default ArtistListinPage;
