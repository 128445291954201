import { useEffect } from "react";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import { CMS_BASE_URL } from "../../config/config";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";

import "./PressReleasesPage.scss";

const PressReleasesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error } = useSWR(`/downloads/Press`);

  if (error) return "error";

  if (!data && !error) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="press-releases-page">
        <PageWrapper>
          <PageHeading text="Press releases" />
          <div className="grid">
            {data.length !== 0 &&
              data.map((item, index) => {
                return (
                  <a href={CMS_BASE_URL + item.file_location} target="_blank" rel="noreferrer">
                    <div className="card">
                      <h3>{item.name}</h3>
                    </div>
                  </a>
                );
              })}
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default PressReleasesPage;
