import React from "react";
import Img from "react-cool-img";
import { Link } from "react-router-dom";
import "./EventCard.scss";

const EventCard = ({ title, image, id }) => {
  return (
    <Link to={"/event/"+id}>
      <div className="event-card">
        <div className="image-container">
          <Img src={image} className="bg-image" />
        </div>
        <div className="link">Events</div>
        <h3>{title}</h3>
      </div>
    </Link>
  );
};

export default EventCard;
