import React from "react";
import Img from "react-cool-img";
import { Link } from "react-router-dom";
import "./ArtistCard.scss";

const ArtistCard = ({ name, image, id }) => {
  return (
    <Link to={"/artist/"+id}>
      <div className="artist-card overlay">
        <Img src={image} className="bg-image" />
        <div className="name">{name}</div>
      </div>
    </Link>
  );
};

export default ArtistCard;
