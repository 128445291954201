import React from "react";
import "./PageHeading.scss";

const PageHeading = ({ text }) => {
  return (
    <div className="page-heading">
      <h2>{text}</h2>
      <div className="line"></div>
    </div>
  );
};

export default PageHeading;
