import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { CMS_BASE_URL } from "../../config/config";

import "./JobsPage.scss";

const JobsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { data: jobsObj, error: jobsError } = useSWR(`/downloads/Jobs`);
  let { data: procurement, error: procurementError } = useSWR(
    `/downloads/Procurements`
  );

  const [state, setState] = useState({
    jobs: "active",
    procurements: "",
    filter: "jobs",
  });

  const handleFilter = (val) => {
    if (val === "jobs") {
      setState({ jobs: "active", procurements: "", filter: "jobs" });
    } else {
      setState({ jobs: "", procurements: "active", filter: "procurements" });
    }
  };

  if (jobsError) return "error";
  if (procurementError) return "error";

  if (!jobsObj && !jobsError) return <Loader />;
  if (!procurement && !procurementError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="jobs-page">
        <PageWrapper>
          <PageHeading text="jobs & procurement" />

          <div className="filters">
            <div
              className={state.jobs}
              onClick={handleFilter.bind(null, "jobs")}
            >
              Jobs
            </div>
            <div
              className={state.procurements}
              onClick={handleFilter.bind(null, "procurements")}
            >
              Procurement
            </div>
          </div>

          <div className="grid">
            {state.filter === "jobs" &&
              (jobsObj.length !== 0 ? (
                jobsObj.map((item, index) => {
                  return (
                    <a href={CMS_BASE_URL + item.file_location} target="_blank" rel="noreferrer">
                      <div className="card">
                        <div className="link">{item.type}</div>
                        <h3>{item.name}</h3>
                      </div>
                    </a>
                  );
                })
              ) : (
                <div>No jobs found.</div>
              ))}

            {state.filter === "procurements" &&
              (procurement.length !== 0 ? (
                procurement.map((item, index) => {
                  return (
                    <a href={CMS_BASE_URL + item.file_location} target="_blank" rel="noreferrer">
                      <div className="card">
                        <div className="link">{item.type}</div>
                        <h3>{item.name}</h3>
                      </div>
                    </a>
                  );
                })
              ) : (
                <div>No Procurements found.</div>
              ))}
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default JobsPage;
