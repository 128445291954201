import Header from "../../components/Header/Header";
//import PageWrapper from "../../components/PageWrapper/PageWrapper";
//import Img from "react-cool-img";
//import {generateImageUrl} from "../../utils/utils";
//import ArtworkCard from "../../components/Cards/ArtworkCard";
//import {CMS_BASE_URL} from "../../config/config";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";

import "./VirtualGalleryPage.scss";
import UnityApp from "../../components/UnityApp/UnityApp";

function VirtualGalleryPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {id} = useParams();
    const {data: event, eventError} = useSWR(`/event/${id}`);

    if (eventError) return "eventError";

    if (!event && !eventError) return <Loader/>;

    return (
        <>
            <Header variant="white"/>
            <main className="virtual-gallery-page">
                <UnityApp event_id={id}/>
                <div className="text-wrapper">
                    <h2>{event.name}</h2>
                    <p>
                        {event.description}
                    </p>
                </div>
            </main>
        </>
    );
}

export default VirtualGalleryPage;
