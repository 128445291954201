import React, {useEffect, useMemo} from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import {API_BASE_URL} from "../../config/config";

function UnityApp({ event_id }) {
    const unityContext = useMemo(() => new UnityContext({
        loaderUrl: `${API_BASE_URL}/event/${event_id}/template/loader`,
        dataUrl: `${API_BASE_URL}/event/${event_id}/template/data`,
        frameworkUrl: `${API_BASE_URL}/event/${event_id}/template/framework`,
        codeUrl: `${API_BASE_URL}/event/${event_id}/template/code`,
    }), [event_id]);

    useEffect(function () {
        unityContext.on("canvas", function (canvas) {
            canvas.scrollIntoView(true);
        });
    }, [unityContext]);

    return (
        <div style={{ height: "100vh", width: "100vw" }}>
            <h3 className={"center-text"}>Loading...</h3>
            <Unity style={{ height: "100%", width: "100%" }} unityContext={unityContext} />
        </div>
    );
}

export default UnityApp;
