import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
/*import Img from "react-cool-img";*/
import Hero from "../../components/Hero/Hero";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import { CMS_BASE_URL } from "../../config/config";

import { ReactComponent as RightIcon } from "../../assets/icons/right-chevron.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
/*import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";*/

import "./EventPage.scss";
import Header from "../../components/Header/Header";
import ArtistCard from "../../components/Cards/ArtistCard";
import EventCard from "../../components/Cards/EventCard";
import ArtworkCard from "../../components/Cards/ArtworkCard";

const EventPage = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { id } = useParams();
const { data:gallery, galleryError } = useSWR(`/art-pieces/event/${id}`);
  let { data: event, error: eventError } = useSWR(`/event/${id}`);
  let { data: artists, error: artistsError } = useSWR(`/event-artists/${id}`);
  let { data: eventsUpcoming, error: eventsUpcomingError } = useSWR(`/event-related/${id}/upcoming`);
  let { data: eventsPast, error: eventsPastError } = useSWR(`/event-related/${id}/past`);
  let { data: eventsNow, error: eventsNowError } = useSWR(`/event-related/${id}/now`);

  if (eventError) return "error";
  if (artistsError) return "error";
  if (eventsUpcomingError) return "error";
  if (eventsPastError) return "error";
  if (eventsNowError) return "error";
  if (galleryError) return "error";

  if (!event && !eventError) return <Loader />;
  if (!artists && !artistsError) return <Loader />;
  if (!eventsUpcoming && !eventsUpcomingError) return <Loader />;
  if (!eventsPast && !eventsPastError) return <Loader />;
  if (!eventsNow && !eventsNowError) return <Loader />;
  if (!gallery && !galleryError) return <Loader />;

  return (
    <>
      <Header variant="transparent" />
      <main className="event-page">
        <Hero image={generateImageUrl(event.hero)}>
          <h1 className="serif">{event.name}</h1>
          <p>{event.description}</p>
        </Hero>
        <PageWrapper>
          <div className="gallery-section">
            {["Virtual","Permanent"].includes(event.type) && (
              <div className="gallery">
                <Link to={"/virtualgallery/" + event.id}>
                  {/*<Img
                    src={CMS_BASE_URL+event.vr_gallery_hero_location}
                    className="bg-image"
                  />*/}
                  <div className="view-button">
                    <div className="content"><span className="title">Virtual Gallery</span> <span className="icon"><EyeIcon /></span></div>
                  </div>
                </Link>
              </div>
            )}
            {/*<div className="gallery">
              <Link to={"/gallery/" + event.id}>
                <Img
                  src={generateImageUrl(event.primary)}
                  className="bg-image"
                />
                <div className="view-button">
                  Image Gallery <ImageIcon />
                </div>
              </Link>
            </div>*/}
          </div>

          <div className="events">
            <div className="space-between">
              <h2 className="f-sans">Gallery</h2>
            </div>
          </div>

          <>
            <main className="gallery-page">
              <PageWrapper>
                <div className="grid">
                  {
                    gallery.map((item,index) => {
                              return (
                                <ArtworkCard
                                  key={index}
                                  image={CMS_BASE_URL+item.images[0].location+item.images[0].filename}
                                  title={item.name}
                                  event={event.name}
                                  
                                  shortDescription={item.description}
                                  medium={item.medium}
                                  size={item.size}
                                />
                            )
                      })
                  }
                  
                  
                </div>
              </PageWrapper>
            </main>
          </>

          <div className="events">
            <div className="space-between">
              <h2 className="f-sans">Artists</h2>
              <Link to="artists">
                <div className="discover link">
                  View all
                  <RightIcon />
                </div>
              </Link>
            </div>
            <div className="grid">
              {artists.map((item, index) => {
                return (
                  <ArtistCard
                    key={index}
                    name={item.fullname}
                    image={generateImageUrl(item.profile)}
                    id={item.id}
                  />
                );
              })}
            </div>
          </div>

          {eventsNow.length !== 0 &&
          <div className="events">
            <div className="space-between">
              <h2 className="f-sans">Ongoing Events</h2>
              <Link to="events">
                <div className="discover link">
                  View all
                  <RightIcon />
                </div>
              </Link>
            </div>
            <div className="grid">
              {
                  eventsNow.length !== 0 ? (
                    eventsNow.map((item,index) => {

                        return (
                            <EventCard
                              key={index}
                              id={item.id}
                              title={item.name}
                              image={CMS_BASE_URL + item.primary_location}
                            />
                      );
                    })
                  ) : (<div>No ongoing Events.</div>)
              }
            </div>
          </div>}

          {eventsUpcoming.length !== 0 &&
          <div className="events">
            <div className="space-between">
              <h2 className="f-sans">Upcoming Events</h2>
              <Link to="events">
                <div className="discover link">
                  View all
                  <RightIcon />
                </div>
              </Link>
            </div>
            <div className="grid">
              {
                  eventsUpcoming.length !== 0 ? (
                    eventsUpcoming.map((item,index) => {

                        return (
                            <EventCard
                              key={index}
                              id={item.id}
                              title={item.name}
                              image={CMS_BASE_URL + item.primary_location}
                            />
                      );
                    })
                  ) : (<div>No Upcoming Events.</div>)
              }
            </div>
          </div>
          }
        </PageWrapper>
        <div className="past-events">
          <div className="wrapper">
            <div className="space-between">
              <h2 className="f-sans">Past Events</h2>
              <Link to="events">
                <div className="discover link">
                  View all
                  <RightIcon />
                </div>
              </Link>
            </div>
            <div className="grid">
                { eventsPast.length !== 0 ? (
                    eventsPast.map((item,index) => {

                        return (
                            <EventCard
                              key={index}
                              id={item.id}
                              title={item.name}
                              image={CMS_BASE_URL + item.primary_location}
                            />
                      );
                    })
                  ) : (<div>No Events.</div>) }
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EventPage;
