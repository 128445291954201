import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import { API_BASE_URL } from "../../config/config";
import "react-nice-dates/build/style.css";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import "./VenueHirePage.scss";


const VenueHirePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setStatusMessage("Sending the message...");

    if (name === "" || email === "" || contact === "" || message === "") {
      console.log("empty");
      return;
    }

    let sdate = "";
    let edate = "";

    sdate = startDate && format(startDate, "dd MMM yyyy", { locale: enGB });
    edate = endDate && format(endDate, "dd MMM yyyy", { locale: enGB });

    let postData = {
      name: name,
      email: email,
      contact_number: contact,
      message: message,
      sdate: sdate,
      edate: edate,
    };

    fetch(API_BASE_URL + "/venue-hire", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }).then((response) => {
      if (response.status === 200) {
        setStatusMessage("Thank you for the interest. We'll be in touch shortly.");
      } else {
        setStatusMessage(
          "Something went wrong. Please try again later or give us a call."
        );
      }
    });

    setName("");
    setEmail("");
    setContact("");
    setMessage("");
  };

  return (
    <>
      <Header variant="white" />
      <main className="venue-hire-page">
        <PageWrapper>
          <PageHeading text="venue hire" />
          <p>Please select your date(s) for venue hire and complete the enquiry form.</p>

          <div className="calender-container">
            <DateRangePickerCalendar
              startDate={startDate}
              endDate={endDate}
              focus={focus}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              onFocusChange={handleFocusChange}
              locale={enGB}
            />
          </div>

          {/*<p className="sans mt-50">Pricing</p>
          <p>Price will be sent once dates are inquired.</p>
          <p className="sans mt-50 text-center">Send and Inquiry</p>*/}

          <p className="mt-50">
            Selected dates:{" "}
            <span className="selected-dates">
              {startDate
                ? format(startDate, "dd MMM yyyy", { locale: enGB })
                : ""}
              -{" "}
              {endDate
                ? format(endDate, "dd MMM yyyy", { locale: enGB })
                : ""}
            </span>
          </p>

          <form onSubmit={(e) => handleSubmit(e)}>
            <div>{statusMessage}</div>
            <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            name="contact"
            placeholder="Contact Number"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <input
            type="email"
            className="email"
            placeholder="e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
            <button>Submit</button>
          </form>
        </PageWrapper>
      </main>
    </>
  );
};

export default VenueHirePage;
