import React, { useEffect } from "react";
import Img from "react-cool-img";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Header from "../../components/Header/Header";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import { CMS_BASE_URL } from "../../config/config";

import "./GalleryPage.scss";
import ArtworkCard from "../../components/Cards/ArtworkCard";

const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {id} = useParams();
  const { data:gallery, galleryError } = useSWR(`/art-pieces/event/${id}`);
  const { data:event, eventError } = useSWR(`/event/${id}`);

  if (galleryError) return "galleryError";
  if (eventError) return "eventError";

  if (!gallery && !galleryError) return <Loader />;
  if (!event && !eventError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="gallery-page">
        <PageWrapper>
          <div className="featured-item">
            <Img src={generateImageUrl(event.primary)} width="100%" />
            <div className="text-wrapper">
              <h2>{event.name}</h2>
              <p>
                {event.description}
              </p>
            </div>
          </div>

          <div className="grid">
            

            {
              gallery.map((item,index) => {
                        return (
                          <ArtworkCard
                            key={index}
                            image={CMS_BASE_URL+item.images[0].location+item.images[0].filename}
                            title={item.name}
                            event={event.name}
                            
                            shortDescription={item.description}
                            medium={item.medium}
                            size={item.size}
                          />
                      )
                })
            }
            
            
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default GalleryPage;
