const headerParser = (data, index) => {
  switch (data.level) {
    case 1:
      return <h1 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
    case 2:
      return <h2 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
    case 3:
      return <h3 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
    case 4:
      return <h4 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
    case 5:
      return <h5 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
    case 6:
      return <h6 key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;

    default:
      return <p key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
  }
};

const paragraphParser = (data, index) => {
  return <p key={index} dangerouslySetInnerHTML={{ __html: data.text }} />;
};

const imageParser = (data, index) => {
  return (
    <img
      key={index}
      alt={data.caption}
      className="img-fluid mb-3"
      src={data.file && data.file.url}
      title={data.caption}
    />
  );
};

const quoteParser = (data, index) => {
  return (
    <blockquote
      className="blockquote"
      key={index}
      dangerouslySetInnerHTML={{ __html: data.text }}
    />
  );
};

const listParser = (data, index) => {
  switch (data.style) {
    case "unordered":
      return (
        <ul key={index} className="unordered-list">
          {data.items.map((item, idx) => (
            <li
              key={`${index}-${idx}`}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </ul>
      );

    case "ordered":
      return (
        <ol key={index} className="ordered-list">
          {data.items.map((item, idx) => (
            <li
              key={`${index}-${idx}`}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </ol>
      );

    default:
      return null;
  }
};

const parseBlocks = (inputString) => {
  const blocks = JSON.parse(inputString).blocks;

  return blocks.map((block, index) => {
    const { type, data } = block;

    switch (type) {
      case "header":
        return headerParser(data, index);

      case "paragraph":
        return paragraphParser(data, index);

      case "image":
        return imageParser(data, index);

      case "quote":
        return quoteParser(data, index);

      case "list":
        return listParser(data, index);

      default:
        return null;
    }
  });
};

export default parseBlocks;
