import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Header from "../../components/Header/Header";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import parseBlocks from "../../utils/editorjs-parser";
import Img from "react-cool-img";

import "./ArtistPage.scss";
import PageHeading from "../../components/PageHeading/PageHeading";
import ArtworkCard from "../../components/Cards/ArtworkCard";

const ArtistPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  let { data: artist, error: artistError } = useSWR(`/artist/` + id);
  let { data: pieces, error: piecesError } = useSWR(`/art-pieces/artist/` + id);

  if (artistError) return "error";
  if (piecesError) return "error";

  if (!artist && !artistError) return <Loader />;
  if (!pieces && !piecesError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="artist-page">
        <PageWrapper>
          <div className="overview">
            <div className="featured-image">
              <Img src={generateImageUrl(artist.profile)} width="100%" />
            </div>
            <div className="about">
              <h1 className="serif">{artist.fullname}</h1>
              <div className="social-links">
                {artist.instagram ? (
                  <a href={artist.instagram} target="_blank" rel="noreferrer">
                    Instagram
                  </a>
                ) : (
                  <div>Instagram</div>
                )}
                {artist.facebook ? (
                  <a href={artist.facebook} target="_blank" rel="noreferrer">
                    Facebook
                  </a>
                ) : (
                  <div>Facebook</div>
                )}
                {artist.twitter ? (
                  <a href={artist.twitter} target="_blank" rel="noreferrer">
                    Twitter
                  </a>
                ) : (
                  <div>Twitter</div>
                )}
              </div>

              {parseBlocks(artist.description)}
            </div>
          </div>
          <PageHeading text="Artworks" />
          <div className="grid">
            {pieces.map((item, index) => {
              return (
                <ArtworkCard
                  key={index}
                  image={generateImageUrl(item.images ? item.images[0] : "")}
                  title={item.name}
                  event={item.event ? item.event.name : ""}
                  shortDescription={
                    item.description.length > 50
                      ? `${item.description.substring(0, 50)} ...`
                      : item.description
                  }
                  longDescription={item.description}
                  medium={item.medium}
                  size={item.size}
                />
              );
            })}
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default ArtistPage;
