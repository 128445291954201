import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Img from "react-cool-img";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";
import parseBlocks from "../../utils/editorjs-parser";

import { CMS_BASE_URL } from "../../config/config";

import "./BlogPostPage.scss";
import EventCard from "../../components/Cards/EventCard";
import ArtistCard from "../../components/Cards/ArtistCard";


const BlogPostPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  let { data: stories, error: storiesError } = useSWR(`/blogs`);
  let { data: story, error: storyError } = useSWR(`/post/${id}`);

  if (storiesError) return "error";
  if (storyError) return "error";

  if (!stories && !storiesError) return <Loader />;
  if (!story && !storyError) return <Loader />;


  return (
    <>
      <Header variant="white" />
      <main className="blog-post-page">
        <PageWrapper>
          <h1 className="serif">
            {story.headline}
          </h1>
          <div className="meta-data">
            <div className="author">{story.author.fullname}</div>
            <div className="date">{story.published_at}</div>
          </div>
          <Img src={generateImageUrl(story.hero)} width="100%" />

          <div className="post-body">
            <div className="content">
                {parseBlocks(story.description)}
            </div>
            <div className="artist-related">
              {story.artist && (<h2>Artist related</h2>)}
              
              {story.artist && (<ArtistCard name={story.artist.fullname} image={CMS_BASE_URL+story.artist.profile_img_loc} id={story.artist.id} />) }

              <br />
              <br />

              {story.event && 
              (
              <EventCard
                title={story.event.name}
                image={CMS_BASE_URL+story.event.primary_location}
              />)
            }
            </div>
          </div>

          <div className="related-articles">
            <h2>Related Articles</h2>

            <div className="grid">
              {stories.map((item,index) => {
                return (
                    item.id !== story.id ?
                      (<Link to={"/post/"+item.id} key={index}>
                       <div className="post">
                        <div className="image-container">
                          <Img src={generateImageUrl(item.hero)} className="bg-image" />
                        </div>
                        <h3>{item.headline}</h3>
                      </div>
                      </Link>) :(<></>)
                    
                  )
              })}
             

              
            </div>
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default BlogPostPage;
