import Img from "react-cool-img";
import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import { CMS_BASE_URL } from "../../config/config";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";

import "./OrganisationPage.scss";
import { useEffect } from "react";

const OrganisationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error } = useSWR(`/members`);

  if (error) return "error";

  if (!data && !error) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="organisation-page">
        <PageWrapper>
          <PageHeading text="Organisation" />
          <div className="grid">
            {data.length !== 0 &&
              data.map((item, index) => {
                return (
                  <div className="card">
                    <div className="image-container">
                      <Img
                        src={CMS_BASE_URL + item.profile_img_loc}
                        className="bg-image"
                      />
                    </div>
                    <h3>{item.fullane}</h3>
                    <div className="link">{item.title}</div>

                    {item.email && <div className="link">E: <a href={"mailTo:"+item.email}>{item.email}</a></div>}
                    {item.contact_number && (
                      <div className="link">T: <a href={"tel:"+item.contact_number}>{item.contact_number}</a></div>
                    )}
                  </div>
                );
              })}
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default OrganisationPage;
