import React, { useEffect } from "react";
import Img from "react-cool-img";
import useSWR from "swr";
import Loader from "../../components/Loader/Loader";
import { generateImageUrl } from "../../utils/utils";

import Header from "../../components/Header/Header";
import PageHeading from "../../components/PageHeading/PageHeading";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import { ReactComponent as EyeIcon } from "../../assets/icons/eye-dark.svg";

import "./Gallery3DPage.scss";
import {Link} from "react-router-dom";
//import {ReactComponent as ImageIcon} from "../../assets/icons/image.svg";

const Gallery3DPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { data:vg , error: vgError } = useSWR(`/virtual-gallery`);
  const { data: perm, permError } = useSWR(`/permanent-collection`);

  if (vgError) return "error";
  if (permError) return "error";

  if (!vg && !vgError) return <Loader />;
  if (!perm && !permError) return <Loader />;

  return (
    <>
      <Header variant="white" />
      <main className="gallery-3d-page">
        <PageWrapper>
          <PageHeading text="virtual gallery" />
          <div className="grid">
            {vg.map((item, index) => {
              return (
                  <Link to={"/virtualgallery/" + item.id}>
                    <div className="gallery">
                      <div className="image-container">
                        <Img
                            src={generateImageUrl(item.hero)}
                            className="bg-image"
                        />
                      </div>
                      <div className="body">
                        <div className="link">Events</div>

                        <div className="content">
                          <h3 className="serif f-26">{item.name}</h3>
                          <p>{item.description}</p>
                          <div className="view-icon">
                            <EyeIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
              );
            })}

            <Link to={"/virtualgallery/" + perm.id}>
                <div className="gallery">
                  <div className="image-container">
                    <Img
                        src={generateImageUrl(perm.hero)}
                        className="bg-image"
                    />
                  </div>
                  <div className="body">
                    <div className="link">Events</div>

                    <div className="content">
                      <h3 className="serif f-26">{perm.name}</h3>
                      <p>{perm.description}</p>
                      <div className="view-icon">
                        <EyeIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>  
            
              
          </div>
        </PageWrapper>
      </main>
    </>
  );
};

export default Gallery3DPage;
