import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import AboutPage from "./pages/AboutPage/AboutPage";
import ArtistListinPage from "./pages/ArtistListinPage/ArtistListinPage";
import ArtistPage from "./pages/ArtistPage/ArtistPage";
import BlogListingPage from "./pages/BlogListingPage/BlogListingPage";
import BlogPostPage from "./pages/BlogPostPage/BlogPostPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import EventListingPage from "./pages/EventListingPage/EventListingPage";
import EventPage from "./pages/EventPage/EventPage";
import Gallery3DPage from "./pages/Gallery3DPage/Gallery3DPage";
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import HomePage from "./pages/HomePage/HomePage";
import JobsPage from "./pages/JobsPage/JobsPage";
import OrganisationPage from "./pages/OrganisationPage/OrganisationPage";
import PressReleasesPage from "./pages/PressReleasesPage/PressReleasesPage";
import VenueHirePage from "./pages/VenueHirePage/VenueHirePage";
import VisitorInfoPage from "./pages/VisitorInfoPage/VisitorInfoPage";

import "./styles/main.scss";
import VirtualGalleryPage from "./pages/VirtualGalleryPage/VirtualGalleryPage";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/event/:id">
            <EventPage />
          </Route>
          <Route path="/events">
            <EventListingPage />
          </Route>
          <Route path="/stories">
            <BlogListingPage />
          </Route>
          <Route path="/post/:id">
            <BlogPostPage />
          </Route>
          <Route path="/artists">
            <ArtistListinPage />
          </Route>
          <Route path="/artist/:id">
            <ArtistPage />
          </Route>
          <Route path="/3d-galleries">
            <Gallery3DPage />
          </Route>
          <Route path="/gallery/:id">
            <GalleryPage />
          </Route>
          <Route path="/virtualgallery/:id">
            <VirtualGalleryPage />
          </Route>
          <Route path="/venue-hire">
            <VenueHirePage />
          </Route>
          <Route path="/organisation">
            <OrganisationPage />
          </Route>
          <Route path="/press">
            <PressReleasesPage />
          </Route>
          <Route path="/jobs-procurement">
            <JobsPage />
          </Route>
          <Route path="/visitor-info">
            <VisitorInfoPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
